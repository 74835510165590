import axios from '@axios';
import HelperService from '@/services/HelperService'

const baseUrl = '/owners';

export default {
  getOwners(filters) {
    const queryString = HelperService.buildSearchQueryString(filters);
    return axios.get(`${baseUrl}${queryString}`);
  },
  createOwner(user) {
    return axios.post(`${baseUrl}`, user);
  },
  deleteOwner(uid) {
    return axios.delete(`${baseUrl}/${uid}`);
  },
  linkPurpleAccount(uid) {
    return axios.post(`${baseUrl}/${uid}/purple-account`);
  },
  getOwner(uid) {
    return axios.get(`${baseUrl}/${uid}`);
  },
  updateOwner(uid, userDto) {
    return axios.put(`${baseUrl}/${uid}`, userDto);
  },
  changePassword(uid, password) {
    return axios.put(`${baseUrl}/${uid}/password`, { password });
  },
  switchGroupPolicyOnOwner(uid, groupId) {
    return axios.put(`${baseUrl}/${uid}/policy`, { group_uid: groupId });
  },
  getQrCodeEnrollment(uid) {
    return axios.get(`${baseUrl}/${uid}/enrollment`);
  },
  getTotp(uid, timestamp) {
    return axios.post(`${baseUrl}/${uid}/totp`, { timestamp });
  },
  getOwnerActivities(uid, filters) {
    const queryString = HelperService.buildSearchQueryString(filters);
    return axios.get(`${baseUrl}/${uid}/activities${queryString}`);
  },
  getActivities(filters) {
    const queryString = HelperService.buildSearchQueryString(filters);
    return axios.get(`${baseUrl}/activities${queryString}`);
  },
  getTopActivities(filters) {
    const queryString = HelperService.buildSearchQueryString(filters);
    return axios.get(`${baseUrl}/activities/top${queryString}`);
  },
  getCountActivities(filters) {
    const queryString = HelperService.buildSearchQueryString(filters);
    return axios.get(`${baseUrl}/activities/count${queryString}`);
  },
  getBalance(uid) {
    return axios.get(`${baseUrl}/${uid}/balance`)
  },
  getOwnersAll(filters) {
    const queryString = HelperService.buildSearchQueryString(filters);
    return axios.get(`${baseUrl}/select${queryString}`);
  },
  getLogins(filters) {
    const queryString = HelperService.buildSearchQueryString(filters);
    return axios.get(`${baseUrl}/activities/logins${queryString}`);
  },
  getTopTags(filters) {
    const queryString = HelperService.buildSearchQueryString(filters);
    return axios.get(`${baseUrl}/activities/top/tags${queryString}`);
  }
};
