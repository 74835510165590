



















import {BCard} from "bootstrap-vue";
import helperService from "@/services/HelperService";

export default {
  components: {
    BCard
  },
  data() {
    return {
      from: new Date(new Date().setDate(new Date().getDate() -  7)),
      to: new Date(),
    }
  },
  computed: {
    fromDate() {
      return new Date(this.from);
    },
    toDate() {
      return new Date(this.to);
    }
  },
  mounted() {
    this.update()
  },
  methods: {
    resetDateRange() {
      this.from = new Date(new Date().setDate(new Date().getDate() -  7))
      this.from.setHours(0,0,0,0)
      this.to = new Date()
      this.to.setHours(23,59,59)

      this.$emit('update', this.from, this.to)
    },
    update() {
      this.fromDate.setHours(0,0,0,0)
      this.toDate.setHours(23,59,59)
      let differenceDays = (this.toDate - this.fromDate) / 1000 / 60 / 60 / 24
      // If difference > 6 months
      if (differenceDays > 186) {
        helperService.showNotfyErr(this.$toast, "error", 'Date range exceeds 6 months, will default to 6 months from start date')
      }
      this.$emit('update', this.fromDate, this.toDate)
    }
  },
}
